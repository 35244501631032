import { Fragment } from "react";

const WAIT_FOR_SCROLL_MS = 1000;

export function ExplodedWordsToSpans({ text }: { text: string | null }) {
  return (
    <Fragment>
      {text?.split(" ").map((word, index) => (
        <span
          key={index}
          className="split-word"
          style={{ display: "inline-block" }}
        >
          {`${word}`}
          {
            /**
             * non-breaking whitespace character. Hyperapp doesn't render HTML
             * entitites like "&nbsp;"
             */ "\u00A0"
          }
        </span>
      ))}
    </Fragment>
  );
}

export function waitForScrollConfig(): Promise<null> {
  return new Promise((resolve) => {
    setTimeout(resolve, WAIT_FOR_SCROLL_MS);
  });
}

export function isAvifSupported(): Promise<boolean> {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(true);
    };
    image.onerror = () => {
      resolve(false);
    };
    image.src =
      "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUEAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAABYAAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAEAAAABAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgSAAAAAAABNjb2xybmNseAACAAIABoAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAAB5tZGF0EgAKBzgADlAgIGkyCR/wAABAAACvcA==";
  });
}
