import { CosmosButtonProps } from "@cosmos/web/react";
import classnames from "classnames/bind";
import { SelectButton } from "../select-button/select-button";
import { notNullish } from "../../helpers/notNullish";
import { analyticsEcommerceVendorClick } from "../../helpers/analytics";
import { isExternalLink } from "../../helpers/isExternalLink";

export interface BuyNowVendorLinksProps {
  id: string;
  label: string;
  className: string;
  buttonSize: CosmosButtonProps["size"];
  links: links[];
  analyticsElementPosition: string;
}

interface links {
  url: string | null;
  label: string | null;
  icon: string | null;
}

export const BuyNowVendorLinks = ({
  id,
  label,
  className,
  buttonSize = "medium",
  links,
  analyticsElementPosition = "VendorLink",
}: BuyNowVendorLinksProps) => {
  return (
    <SelectButton
      id={id}
      buttonClassName={classnames(className)}
      buttonSize={buttonSize}
      label={label ?? ""}
      items={
        links.filter(notNullish).map((item) => ({
          label: item?.label ?? "",
          url: item?.url ?? "",
          icon: item?.icon ?? undefined,
          onClick: () =>
            analyticsEcommerceVendorClick({
              value: item?.label ?? "",
              clickUrl: item?.url ?? "",
              elementPosition: analyticsElementPosition,
              method: isExternalLink(item?.url ?? "")
                ? "Outbound: TRUE"
                : "Outbound: FALSE",
              contentType: "Button Click, Buy now",
            }),
        })) ?? []
      }
    />
  );
};
