import { useThirdPartyScriptMikmak } from "../../helpers/useThirdPartyScriptMikmak";
import { CosmosButton, CosmosButtonProps } from "@cosmos/web/react";
import classnames from "classnames/bind";

export interface BuyNowMikmakProps {
  mikmakIds: (string | null)[] | null;
  label: string;
  className: string;
  buttonSize: CosmosButtonProps["size"];
}

export const BuyNowMikmak = ({
  mikmakIds,
  label,
  className,
  buttonSize = "medium",
}: BuyNowMikmakProps) => {
  const ThirdPartyScriptMikmak = useThirdPartyScriptMikmak();

  return (
    <>
      {ThirdPartyScriptMikmak}
      <CosmosButton
        data-mm-wtbid="66d0c1b09ce37048eeaa27a4"
        data-mm-ids={mikmakIds?.join(",")}
        size={buttonSize}
        className={classnames(className)}
      >
        {label ?? ""}
      </CosmosButton>
    </>
  );
};
