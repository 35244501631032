import classNames from "classnames";
import classNamesBind from "classnames/bind";
import {
  CosmosButtonProps,
  CosmosButton,
  CosmosIconChevronDown,
  CosmosIconAmazon,
  CosmosIconGlobeGeo,
  CosmosIconExternalLink,
  CosmosText,
} from "@cosmos/web/react";
import styles from "./select-button.module.css";
import { useState } from "react";
import { isExternalLink } from "../../helpers/isExternalLink";

const cx = classNamesBind.bind(styles);

interface items {
  url: string;
  icon?: string; // "Amazon"
  label: string;
  onClick?: () => void;
}

export const SelectButton = ({
  buttonClassName,
  disabled,
  buttonKind = "primary",
  buttonSize = "medium",
  label,
  items,
  id,
}: {
  buttonClassName?: string;
  disabled?: boolean;
  buttonKind?: CosmosButtonProps["kind"];
  buttonSize: CosmosButtonProps["size"];
  label: string; // Label is only used when there are more than 1 vendor links
  items: items[];
  id: string; // ID is only used when there are more than 1 vendor links
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onClick = () => setIsExpanded((current) => !current);

  return (
    <>
      {items.length === 1 ? (
        <>
          {items.map((item, index) => (
            <CosmosButton
              key={index}
              className={classNames(buttonClassName)}
              href={item.url}
              target={isExternalLink(item.url ?? "") ? "_blank" : undefined}
              icon-placement="before"
              kind={buttonKind}
              onClick={item.onClick}
              size={buttonSize}
            >
              {item.icon === "amazon" ? (
                <CosmosIconAmazon slot="icon" aria-hidden="true" />
              ) : (
                <CosmosIconGlobeGeo slot="icon" aria-hidden="true" />
              )}
              {item.label}
            </CosmosButton>
          ))}
        </>
      ) : (
        <div className={cx("container")}>
          <CosmosButton
            className={classNames(buttonClassName, cx("button"))}
            shape="pill"
            size={buttonSize}
            icon-placement="after"
            onClick={onClick}
            disabled={disabled}
            kind={buttonKind}
            type="button" // prevent accidental form submits
            aria-haspopup="listbox"
            aria-expanded={isExpanded ? true : undefined}
            aria-controls={`${id}-button-select`}
            onBlur={(event) => {
              if (event.relatedTarget === null && isExpanded) {
                setIsExpanded(false);
              }
            }}
          >
            <CosmosIconChevronDown
              aria-hidden="true"
              className={cx("icon")}
              slot="icon"
            />
            {label}
          </CosmosButton>
          <div
            hidden={!isExpanded}
            id={`${id}-button-select`}
            role="listbox"
            className={cx("listbox")}
            data-open={isExpanded}
          >
            {items.map((item, index) => (
              <a
                key={index}
                className={cx("listbox__option")}
                href={item.url}
                target={isExternalLink(item.url ?? "") ? "_blank" : undefined}
                onClick={item.onClick}
              >
                <div className={cx("listbox__option-content")}>
                  {item.icon === "amazon" ? (
                    <CosmosIconAmazon
                      aria-hidden="true"
                      className={cx("listbox__option-prefix")}
                      slot="icon"
                    />
                  ) : (
                    <CosmosIconGlobeGeo
                      aria-hidden="true"
                      className={cx("listbox__option-prefix")}
                      slot="icon"
                    />
                  )}

                  <CosmosText
                    className={cx("listbox__option-label")}
                    size="small"
                    clamp={1}
                  >
                    {item.label}
                  </CosmosText>

                  <CosmosIconExternalLink
                    className={cx("listbox__option-suffix")}
                  />
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
