"use client";

import classNames from "classnames/bind";
import {
  selectShowGreenDot,
  selectShowHighInSugarIcon,
} from "../../helpers/selectors";
import highInSugarIconClUrl from "./high-in-sugar-icon--cl.svg";
import highInSugarIconUrl from "./high-in-sugar-icon.svg";
import vegetarianUrl from "./vegetarian-mark.svg";
import styles from "./dietary-marks.module.css";
import { useLocaleStringContext } from "../../helpers/LocaleStringContext";

const cx = classNames.bind(styles);

export interface DietaryMarksProps {
  kind: "vegetarian" | "high-sugar";
  sugarFree?: boolean;
  className?: string;
}

export const DietaryMarks = ({
  className,
  kind,
  sugarFree = false,
}: DietaryMarksProps) => {
  const localeString = useLocaleStringContext();

  const showVegetarianMark = selectShowGreenDot(localeString);
  const showHighInSugarMark = selectShowHighInSugarIcon(localeString);

  /* When styling it's useful to force all marks to appear */
  // const showVegetarianMark = true;
  // const showHighInSugarMark = true;

  const highSugarUrl =
    localeString === "cl-es"
      ? highInSugarIconClUrl.src
      : highInSugarIconUrl.src;

  return (
    <>
      {showHighInSugarMark && kind === "high-sugar" && !sugarFree && (
        <img
          src={highSugarUrl}
          alt="High in sugar mark"
          className={cx("high-sugar", className)}
          data-localestring={localeString}
        />
      )}
      {showVegetarianMark && kind === "vegetarian" && (
        <img
          src={vegetarianUrl.src}
          alt="Vegetarian mark"
          className={cx("vegetarian", className)}
          data-localestring={localeString}
        />
      )}
    </>
  );
};
