import { useEffect, useState } from "react";
import {
  cookieConsentStatus,
  ConsentStatus,
} from "../helpers/cookieConsentStatus";

// Script with Do Not Track set to "true"
const mikmakScript = `(function(e,d){try{var a=window.swnDataLayer=window.swnDataLayer||{};a.appId=e||a.appId,a.eventBuffer=a.eventBuffer||[],a.loadBuffer=a.loadBuffer||[],a.push=a.push||function(e){a.eventBuffer.push(e)},a.load=a.load||function(e){a.loadBuffer.push(e)},a.dnt=a.dnt!=null?a.dnt:d;var t=document.getElementsByTagName("script")[0],n=document.createElement("script");n.async=!0,n.src="//wtb-tag.mikmak.ai/scripts/"+a.appId+"/tag.min.js",t.parentNode.insertBefore(n,t)}catch(e){console.log(e)}}("66d0c18fd4890e2b03b59ef4", true));`;

// Set an ID to see if it's already been added
const mikmakScriptId = "mikmakscript";

// Helper to update DNT (Do Not Track) flag via Mikmak's API
const setDNTStatus = (dnt: boolean) => {
  if (typeof window !== "undefined") {
    // Check if window.swnDataLayer is an object and has the setDNT function
    if (
      typeof window.swnDataLayer === "object" &&
      window.swnDataLayer !== null
    ) {
      const swnDataLayer = window.swnDataLayer as {
        setDNT?: (dnt: boolean) => void;
        dnt?: boolean;
      };

      if (swnDataLayer.setDNT) {
        swnDataLayer.setDNT(dnt); // Set the DNT status if the method exists
      } else {
        swnDataLayer.dnt = dnt; // Fallback: directly set the dnt property
      }
    } else {
      // If swnDataLayer doesn't exist, initialize it
      window.swnDataLayer = { dnt: dnt };
    }
  }
};

export const useThirdPartyScriptMikmak = () => {
  const [hasCookieConsent, setHasCookieConsent] =
    useState<ConsentStatus | null>(null);

  const updateConsentStatus = () => {
    const consentStatus = cookieConsentStatus();
    setHasCookieConsent(consentStatus);
  };

  // Add OneTrust Event listeners to detect cookie changes
  useEffect(() => {
    // Check consent on initial load
    updateConsentStatus();

    // Add event listener for consent updates
    window.addEventListener("OneTrustGroupsUpdated", updateConsentStatus);

    return () => {
      window.removeEventListener("OneTrustGroupsUpdated", updateConsentStatus);
    };
  }, []);

  // Append Mikmake script to the <head> once
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      !document.getElementById(mikmakScriptId)
    ) {
      // Load the Mikmak script
      const script = document.createElement("script");
      script.id = mikmakScriptId;
      script.innerHTML = mikmakScript;
      document.head.appendChild(script);
    }
  }, []);

  // When OneTrust updates preferences change Mikmak tracking status
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      hasCookieConsent?.performance !== undefined
    ) {
      // Check if consent is available and update DNT status accordingly
      const dntStatus = !hasCookieConsent.performance; // true if cookies are disabled (DNT on), false if enabled (DNT off)
      setDNTStatus(dntStatus);
      // console.log(
      //   `Mikmak DNT status updated to ${dntStatus ? "enabled" : "disabled"}`,
      // );
    }
  }, [hasCookieConsent?.performance]);

  return null;
};
