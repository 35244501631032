/**
 * Check if a link is external,
 * useful for adding `taget="_blank"` or Analytics information
 */

export const isExternalLink = (url: string): boolean => {
  // Check if the input is not a string or empty
  if (typeof url !== "string" || url.trim() === "") {
    return false; // Not a valid URL
  }

  // Check if the link is a fragment identifier
  if (url.startsWith("#")) {
    return false; // Internal link
  }

  // Check if the link is an absolute URL
  const isAbsoluteUrl = /^(https?:)?\/\//.test(url);

  // If it's not an absolute URL, treat it as internal
  if (!isAbsoluteUrl) {
    return false; // Internal link
  }

  try {
    // Attempt to create a URL object
    const urlObject = new URL(url);

    // Extract hostname from the link
    const linkHostname = urlObject.hostname;

    // Check if window is defined (for client-side rendering)
    // Extract hostname from the current window location
    const currentHostname =
      typeof window !== "undefined" ? window.location.hostname : "";

    // Compare hostnames to determine if the link is external
    return linkHostname !== currentHostname;
  } catch (error) {
    // Catch any errors during URL creation (invalid URL)
    return false; // Not a valid URL
  }
};
