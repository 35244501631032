import { useRef } from "react";

export interface AnalyticsTrackingEvent {
  id: string;
  event: string;
}

export const useAnalyticsTrackingState = () => {
  const ref = useRef<AnalyticsTrackingEvent[]>([]);

  return ref.current;
};
