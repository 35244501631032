/**
 * Onetrust / Optanon Cookie Consent helper.
 * This simple helper checks what cookie groups the user has consented to
 */

export type ConsentStatus = {
  strictlyNecessary: boolean;
  performance: boolean;
  thirdParty: boolean; // normally called "functional" in Onetrust
};

/**
 * A helper function to check what consent options have been set using OneTrust.
 */
export const cookieConsentStatus = (): ConsentStatus | null => {
  if (typeof window === "undefined" || !window.OnetrustActiveGroups) {
    // console.warn("OneTrust is not loaded or running on the server side.");
    return null;
  }

  const activeGroups = window.OnetrustActiveGroups;

  const cookieConsentStatus: ConsentStatus = {
    strictlyNecessary: activeGroups.includes("C0001"), // Strictly Necessary Cookies
    performance: activeGroups.includes("C0002"), // Performance Cookies
    thirdParty: activeGroups.includes("C0003"), // C0003 is normally called Functional Cookies?
  };

  return cookieConsentStatus;
};
